import React from "react";
import "./Terminal.css";
import "../../styles/global.css";
import Terminal from "terminal-in-react";
import { commands } from "../../utils/comands";
import { descriptions, translate } from "../../utils/translations";

const TerminalComponent = ({
  background,
  color,
  barColor,
  style,
  prompt,
  promptSymbol = "$",
}) => {
  return (
    <Terminal
      allowTabs={false}
      color={color}
      backgroundColor={background}
      barColor={barColor}
      style={style}
      prompt={prompt}
      promptSymbol={promptSymbol}
      commands={commands}
      descriptions={descriptions}
      msg={translate("HELP_COMMAND")}
    />
  );
};

export default TerminalComponent;
