import React, { Suspense, useEffect } from "react";
import { Router } from "@reach/router";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Resume from "./pages/Resume";
import LinuxTerminal from "./pages/LinuxTerminal";
import WindowsCMD from "./pages/WindowsCMD";
import MacTerminal from "./pages/MacTerminal";

const App = () => {
  useEffect(() => {}, []);
  return (
    <Suspense fallback={<div>Cargando...</div>}>
      <Router>
        <NotFound default />
				<Home path="/" />
        <Resume path="/resume" />
        <LinuxTerminal path="/linux" />
        <WindowsCMD path="/windows" />
        <MacTerminal path="/macos" />
      </Router>
    </Suspense>
  );
};

export default App;
