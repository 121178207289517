import React from "react";
import TerminalComponent from "../components/Terminal/Terminal";
import "../styles/linux.css";
import { getLanguage } from "../utils/translations";

const LinuxTerminal = () => {
  const background = "rgba(56, 4, 40, .9);";
  const color = "white";
  const promptColor = "white";
  const barColor = "#46433E";
  const style = { width: "100%" };
  const language = getLanguage();
  return (
    <div className="wrapper linux">
      <TerminalComponent
        language={language}
        background={background}
        color={color}
        barColor={barColor}
        style={style}
        prompt={promptColor}
        promptSymbol={"root@user:$"}
      />
    </div>
  );
};

export default LinuxTerminal;
