import React from "react";
import "../styles/global.css";
import "../styles/404.css";
import styled from "styled-components";

import astronautImage from "../assets/img/astrounaut404.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const NotFound = () => {
  const url = window.location.pathname;
  return (
    <div className="root">
      <div className="main">
        <Container>
          <div className="notFoundImageContainer">
            <img
              className="img-fluid"
              src={astronautImage}
              alt="404 not found"
            />
          </div>
          <h1>404</h1>
          <h3>La página que está buscando no existe.</h3>
          <btn className="w-max-content m-0-auto" href="/">
            Volver al inicio
          </btn>
        </Container>
      </div>
    </div>
  );
};

export default NotFound;
