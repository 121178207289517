import React from "react";
import RESUME from "../assets/cv.pdf";
import { translate } from "../utils/translations";

const Resume = () => {
  React.useEffect(() => {
    window.open(RESUME);
  }, []);

  return (
    <div className="wrapper resume">
      {translate("RESUME_LOADED")}
      <a href="/">{translate("GO_BACK")}</a>
    </div>
  );
};

export default Resume;
