import React from "react";
import TerminalComponent from "../components/Terminal/Terminal";
import backgroundImage from "../assets/img/windows.jpg";
import { getLanguage } from "../utils/translations";
const WindowsCMD = () => {
  const background = "black";
  const color = "green";
  const promptColor = "green";
  const barColor = "white";
  const style = { width: "100%" };
  const language = getLanguage();

  return (
    <div
      className="wrapper windows"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <TerminalComponent
        language={language}
        background={background}
        color={color}
        barColor={barColor}
        style={style}
        prompt={promptColor}
        promptSymbol={">:"}
      />
    </div>
  );
};

export default WindowsCMD;
